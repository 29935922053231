import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, discountCodeFilter) => {
  let storeCodeFilterObj = {
    member: "MvOrderTransactions.shipFromStoreCode",
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let discountCodeFilterObj = {
    member: "MvOrderTransactions.discountCode",
    operator: "contains",
    values: [discountCodeFilter]
  }


  if (discountCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(discountCodeFilterObj)
    }
  }

  return query
}

const DiscountBreakdownByItemQuery = (storeFilter, startDateFilter, endDateFilter, discountCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.discountBreakdownByItemPage.name'),
    tableHeaders: {
      "MvOrderTransactions.discountCode":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.discountCode'), type: "string" },
      "MvOrderTransactions.discountNameEnHk":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.discountNameEnHk'), type: "string" },
      "MvOrderTransactions.discountNameZhHk":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.discountNameZhHk'), type: "string" },
      "MvOrderTransactions.sumLineTotal":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.lineTotal'), type: "number" },
      "MvOrderTransactions.sumQuantity":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.quantity'), type: "number" },
      "OrderLinePropertySkus.code":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.skuCode'), type: "string" },
      "OrderLinePropertySkus.internalUseTitle":  { name: t('page.fnb.discountBreakdownByItemPage.tableHeader.skuInternalUseTitle'), type: "string" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'discount_breakdown_by_item_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.DiscountBreakdownByItem.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, discountCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownByItemPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [ discountCodeFilter, setDiscountCodeFilter ] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = DiscountBreakdownByItemQuery(storeFilter, startDateFilter, endDateFilter, discountCodeFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.discountBreakdownByItemReport')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.discountBreakdownByItemPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]}
        startDateLabel={t('page.fnb.discountBreakdownByItemPage.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.fnb.discountBreakdownByItemPage.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.fnb.discountBreakdownByItemPage.filter.orderCompletedAt.endDate')}/>
      <TextFieldComponent id={"discount_code_filter"} state={discountCodeFilter} pageChangerArr={[setPage]} 
        stateChanger={setDiscountCodeFilter} textLabel={t('page.fnb.discountBreakdownByItemPage.filter.discountCode')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default DiscountBreakdownByItemPage;