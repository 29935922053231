const DayEndQueries = {
  OrderTotalDayEnd: {
    query: {
      "measures": [
        "OrderDayEnd.count",
        "OrderDayEnd.orderTotal",
        "OrderDayEnd.membershipCharges",
        "OrderDayEnd.netSales",
        "OrderDayEnd.avgBillAmount",
        "OrderDayEnd.pax",
        "OrderDayEnd.perPersonSpending",
        "OrderDayEnd.grossSales",
        "OrderDayEnd.discounts",
        "OrderDayEnd.serviceCharges",
        "OrderDayEnd.shippingFees",
        "OrderDayEnd.orderAmountRounding",
        "OrderDayEnd.foodGrossSales",
        "OrderDayEnd.beverageGrossSales",
        "OrderDayEnd.otherGrossSales",
        "OrderDayEnd.foodPercentage",
        "OrderDayEnd.beveragePercentage",
        "OrderDayEnd.otherPercentage",
      ],
      "timeDimensions":[],
      "dimensions": [],
      "filters": [
        {
          "member": "OrderDayEnd.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEnd.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderDayEnd.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade",
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderTotalDayEndEM: {
    query: {
      "measures": [
        "OrderDayEndEM.count",
        "OrderDayEndEM.avgBillAmount",
        "OrderDayEndEM.pax",
        "OrderDayEndEM.perPersonSpending",
      ],
      "timeDimensions":[],
      "dimensions": [],
      "filters": [
        {
          "member": "OrderDayEndEM.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEndEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderDayEndEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade",
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  VoidItemDayEnd: {
    query: {
      "measures": [
        "CartLinePropertiesDayEnd.sumCachedCalculationQuantity",
      ],
      "dimensions": [],
      "timeDimensions":[],
      "filters": [
        {
          "member": "CartLinePropertiesDayEnd.softDelete",
          "operator": "equals",
          "values": [
            "true"
          ]
        },
        {
          "member": "CartLinePropertiesDayEnd.mergeToCartId",
          "operator": "notSet"
        },
      ],
      "limit": 50000
     }
  },
  VoidOrderTotalDayEnd: {
     query: {
      "measures": [
        "OrderDayEnd.count",
        "OrderDayEnd.orderTotal",
      ],
      "dimensions": [],
      "timeDimensions":[],
      "filters": [
        {
          "member": "OrderDayEnd.orderState",
          "operator": "equals",
          "values": [
            "void_completed"
          ]
        },
        {
          "member": "OrderDayEnd.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderDayEnd.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000
     }
  },
  TipDayEnd: {
     query: {
      "measures": [
        "PaymentRequests.sumTip",
      ],
      "timeDimensions":[],
      "dimensions": [],
      "filters": [
        {
          "member": "Orders.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "Orders.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "PaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "authorized"
          ]
        },
      ],
      "limit": 50000
     }
  },
  NetSalesByStore: {
    query: {
      "measures": [
        "OrderDayEnd.orderTotal",
        "OrderDayEnd.count",
      ],
      "timeDimensions":[],
      "dimensions": [
        "OrderDayEnd.shipmentStoreCode",
        "OrderDayEnd.orderSource",
      ],
      "order": {
        "OrderDayEnd.shipmentStoreCode": "asc",
        "OrderDayEnd.orderSource": "asc",
      },
      "filters": [
        {
          "member": "OrderDayEnd.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEnd.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "OrderDayEnd.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  NetSalesByStoreEM: {
    query: {
      "measures": [
        "OrderDayEnd.netSales",
        "OrderDayEnd.count",
      ],
      "timeDimensions":[],
      "dimensions": [
        "OrderDayEnd.shipmentStoreCode",
        "OrderDayEnd.orderSource",
      ],
      "order": {
        "OrderDayEnd.shipmentStoreCode": "asc",
        "OrderDayEnd.orderSource": "asc",
      },
      "filters": [
        {
          "member": "OrderDayEnd.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEnd.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "OrderDayEnd.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PaymentByStoreType: {
    query: {
    "measures": [
      "PaymentRequests.sumAmount",
      "PaymentRequests.sumTip",
      "PaymentRequests.sumGrandTotal",
      "Orders.count"
    ],
    "timeDimensions":[
    ],
    "dimensions": [
      "Orders.shipmentStoreCode",
      "PaymentProviders.code",
      "PaymentProviderTranslations.name",
      "Currencies.code",
      "PaymentRequests.state"
    ],
    "order": {
      "Orders.shipmentStoreCode": "asc",
      "PaymentProviders.code": "asc",
      "PaymentRequests.state": "asc",
      "Currencies.code": "asc",
    },
    "filters": [
      {
        "member": "Orders.state",
        "operator": "equals",
        "values": [
          "completed"
        ]
      },
      {
        "member": "Orders.commerceChannelCode",
        "operator": "notEquals",
        "values": [
          "membership_upgrade"
        ]
      },
      {
        "member": "PaymentRequests.state",
        "operator": "equals",
        "values": [
          "completed", "refunded", "authorized"
        ]
      },
    ],
    "limit": 50000,
      "renewQuery": true,
    }
  },
  TenderByStoreStaff:{
    query: {
      "measures": [
        "PaymentRequests.sumAmount",
        "PaymentRequests.sumTip",
        "PaymentRequests.sumGrandTotal",
        "Orders.count"
      ],
      "timeDimensions":[
      ],
      "dimensions": [
        "Orders.shipmentStoreCode",
        "PaymentProviders.code",
        "PaymentProviderTranslations.name",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName",
        "Currencies.code",
        "PaymentRequests.state"
      ],
      "order": {
        "Orders.shipmentStoreCode": "asc",
        "PaymentProviders.code": "asc",
        "PaymentRequests.state": "asc",
        "Currencies.code": "asc",
      },
      "filters": [
        {
          "member": "Orders.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "Orders.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        },
        {
          "member": "PaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "refunded", "authorized"
          ]
        },
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DiscountBreakdown: {
    query: {
      "measures": [
        "OrderTransactionsDiscount.totalAmount",
        "OrderTransactionsDiscount.sumQuantity"
      ],
      "timeDimensions": [
      ],
      "order": [
        [
          "OrderTransactionsDiscount.storeCode",
          "asc"
        ],
        [
          "OrderTransactionsDiscount.discountCode",
          "asc"
        ]
      ],
      "filters": [
        {
          "member": "OrderTransactionsDiscount.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderTransactionsDiscount.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "dimensions": [
        "OrderTransactionsDiscount.storeCode",
        "OrderTransactionsDiscount.discountCode",
        "OrderTransactionsDiscount.discountName"
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderSettlementPaymentLevel:{
    query: {
      "dimensions": [
        "ShipmentStores.code",
        "Orders.referenceNumber",
        "Orders.createdAt",
        "Orders.completedAt",
        "Orders.confirmedAt",
        "Orders.state",
        "PaymentRequests.state",
        "Orders.testOrder",
        "PaymentRequests.received",
        "PaymentRequests.tipOrCharge",
        "PaymentRequests.amount",
        "Currencies.code",
        "PaymentProviders.code",
        "PaymentProviderTranslations.name",
        "PaymentRequests.providerPaymentType",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName",
        "CommerceChannels.code",
        "Orders.holdAt",
        "Orders.reservedAt",
        "Orders.declinedAt",
        "Orders.waitingForApprovalAt",
        "Orders.cancelledAt",
        "Orders.errorAt",
        "Orders.voidInProgressAt",
        "Orders.voidCompletedAt",
      ],
      "filters": [
        {
          "member": "CommerceChannels.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "order": {
        "ShipmentStores.code": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  OrderSettlementOrderLevel:{
    query: {
      "measures": [
        "PaymentRequests.sumReceived",
        "PaymentRequests.sumTipOrCharge",
        "PaymentRequests.sumAmount"
      ],
      "dimensions": [
        "ShipmentStores.code",
        "Orders.referenceNumber",
        "Carts.referenceNumber",
        "Orders.state",
        "Orders.createdAt",
        "Orders.completedAt",
        "Orders.confirmedAt",
        "PaymentRequests.state",
        "Orders.testOrder",
        "Currencies.code",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName",
        "CommerceChannels.code",
        "Orders.holdAt",
        "Orders.reservedAt",
        "Orders.declinedAt",
        "Orders.waitingForApprovalAt",
        "Orders.cancelledAt",
        "Orders.errorAt",
        "Orders.voidInProgressAt",
        "Orders.voidCompletedAt",
      ],
      "filters": [
        {
          "member": "CommerceChannels.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "order": {
        "ShipmentStores.code": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PaymentByStoreTypeBreakdown:{
    query: {
      "measures": [
      ],
      "dimensions": [
        "Orders.referenceNumber",
        "Orders.cartReferenceNumber",
        "Orders.completedAt",
        "PaymentProviders.code",
        "PaymentProviders.internalUseTitle",
        "PaymentRequests.state",
        "PaymentRequests.amount",
        "PaymentRequests.tip",
        "PaymentRequests.grandTotal",
        "PaymentRequests.fullRefundedAt",
        "PaymentRequests.completedAt",
      ],
      "filters": [
        {
          "member": "Orders.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "PaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "refunded", "authorized"
          ]
        },
        {
          "member": "CommerceChannels.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "order": {
        "Orders.completedAt": "desc",
        "Orders.referenceNumber": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  PaymentByStoreStaff:{
    query: {
      "measures": [
        "Orders.count",
        "PaymentRequests.sumAmount",
        "PaymentRequests.sumTip"
      ],
      "dimensions": [
        "ShipmentStores.code",
        "PaymentProviders.code",
        "InternalAppOperators.staffNumber",
        "InternalAppOperators.firstName",
        "InternalAppOperators.lastName"
      ],
      "filters": [
        {
          "member": "Orders.state",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "PaymentRequests.state",
          "operator": "equals",
          "values": [
            "completed", "authorized"
          ]
        },
        {
          "member": "CommerceChannels.code",
          "operator": "notEquals",
          "values": [
            "membership_upgrade"
          ]
        }
      ],
      "order": {
        "ShipmentStores.code": "asc",
        "InternalAppOperators.staffNumber": "asc"
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  FSOrderSummary: {
    query: {
      "measures": [
        "OrderDayEndFS.count",
        "OrderDayEndFS.netSales",
        "OrderDayEndFS.grossSales",
        "OrderDayEndFS.totalQuantity",
        "OrderDayEndFS.dpGrossSales",
        "OrderDayEndFS.dpTotalQuantity",
        "OrderDayEndFS.dpDiscount",
        "OrderDayEndFS.dpNetSales",
        "OrderDayEndFS.dpPercentage",
        "OrderDayEndFS.giftGrossSales",
        "OrderDayEndFS.giftTotalQuantity",
        "OrderDayEndFS.giftDiscount",
        "OrderDayEndFS.giftNetSales",
        "OrderDayEndFS.giftPercentage",
        "OrderDayEndFS.con1GrossSales",
        "OrderDayEndFS.con1TotalQuantity",
        "OrderDayEndFS.con1Discount",
        "OrderDayEndFS.con1NetSales",
        "OrderDayEndFS.con1Percentage",
        "OrderDayEndFS.con2GrossSales",
        "OrderDayEndFS.con2TotalQuantity",
        "OrderDayEndFS.con2Discount",
        "OrderDayEndFS.con2NetSales",
        "OrderDayEndFS.con2Percentage",
        "OrderDayEndFS.rec1GrossSales",
        "OrderDayEndFS.rec1TotalQuantity",
        "OrderDayEndFS.rec1Discount",
        "OrderDayEndFS.rec1NetSales",
        "OrderDayEndFS.rec1Percentage",
        "OrderDayEndFS.t2GrossSales",
        "OrderDayEndFS.t2TotalQuantity",
        "OrderDayEndFS.t2Discount",
        "OrderDayEndFS.t2NetSales",
        "OrderDayEndFS.t2Percentage",
        "OrderDayEndFS.otherGrossSales",
        "OrderDayEndFS.otherTotalQuantity",
        "OrderDayEndFS.otherDiscount",
        "OrderDayEndFS.otherNetSales",
        "OrderDayEndFS.otherPercentage",
      ],
      "timeDimensions": [],
      "dimensions": [],
      "filters": [
        {
          "member": "OrderDayEndFS.orderState",
          "operator": "equals",
          "values": [
            "completed", "confirmed"
          ]
        }
      ],
      "order": {},
      "limit": 50000,
      "renewQuery": true,
    }
  },
  CHEOrderSummary: {
    query: {
      "measures": [
        "OrderDayEndCHEV2.count",
        "OrderDayEndCHEV2.netSales",
        "OrderDayEndCHEV2.grossSales",
        "OrderDayEndCHEV2.sumSkuQuantity",
        "OrderDayEndCHEV2.discount",
        "OrderDayEndCHEV2.orderRounding",
      ],
      "timeDimensions": [],
      "dimensions": [],
      "filters": [
        {
          "member": "OrderDayEndCHEV2.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        }
      ],
      "order": {},
      "limit": 50000,
      "renewQuery": true,
    }
  },
  CHEOrderSummaryDetail: {
    query: {
      "measures": [
        "OrderDayEndCHEV2.grossSales",
        "OrderDayEndCHEV2.sumSkuQuantity",
        "OrderDayEndCHEV2.discount",
        "OrderDayEndCHEV2.netSales"
      ],
      "timeDimensions": [],
      "dimensions": [
        "OrderDayEndCHEV2.catType",
      ],
      "filters": [
        {
          "member": "OrderDayEndCHEV2.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEndCHEV2.catType",
          "operator": "set",
        }
      ],
      "order": {
         "OrderDayEndCHEV2.catType": "asc",
      },
      "limit": 50000,
      "renewQuery": true,
    }
  },
  DailySales: {
    query: {
      "measures": [],
      "timeDimensions": [],
      "dimensions": [
        "OrderDayEndEM.orderCompletedAt",
        "OrderDayEndEM.orderCompletedAt2",
        "OrderDayEndEM.referenceNumber",
        "OrderDayEndEM.netSalesSingle"
      ],
      "order": {
        "OrderDayEndEM.orderCompletedAt": "asc"
      },
      "filters": [
        {
          "member": "OrderDayEndEM.orderState",
          "operator": "equals",
          "values": [
            "completed"
          ]
        },
        {
          "member": "OrderDayEndEM.commerceChannelCode",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        },
        {
          "member": "OrderDayEndEM.orderSource",
          "operator": "notEquals",
          "values": [
            "membership_upgrade", "membership_upgrade_pos"
          ]
        }
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  },
  FnBTotalAndDiscountBreakDown: {
    query: {
      "measures": [],
      "timeDimensions": [],
      "dimensions": [
        "FnBTotalAndDiscountBreakDown.storeCode",
        "FnBTotalAndDiscountBreakDown.dineInTableNumber",
        "FnBTotalAndDiscountBreakDown.serviceMode",
        "FnBTotalAndDiscountBreakDown.commerceChannelCode",
        "FnBTotalAndDiscountBreakDown.orderGrossTotal",
        "FnBTotalAndDiscountBreakDown.foodGrossTotal",
        "FnBTotalAndDiscountBreakDown.bvGrossTotal",
        "FnBTotalAndDiscountBreakDown.rounding",
        "FnBTotalAndDiscountBreakDown.serviceCharge",
        "FnBTotalAndDiscountBreakDown.discountTotal",
        "FnBTotalAndDiscountBreakDown.allotedFoodDiscount",
        "FnBTotalAndDiscountBreakDown.allotedBvDiscount",
        "FnBTotalAndDiscountBreakDown.unallottableDiscount",
        "FnBTotalAndDiscountBreakDown.orderNetTotal"
      ],
      "order": {
        "FnBTotalAndDiscountBreakDown.storeCode": "asc",
        "FnBTotalAndDiscountBreakDown.openTableTime": "asc"
      },
      "filters": [
      ],
      "limit": 50000,
      "renewQuery": true,
    }
  }
}

export default DayEndQueries