import React, { useState, useContext, useMemo } from "react";
import Header from '../../components/Header';
import NumberComponent from '../../components/NumberComponent';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextComponent from '../../components/TextComponent';
import ChartComponent from '../../components/ChartComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember, languageFilter, languageMember) => {
  let storeCodeFilterObj = {
    member: storeMember,
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  if (languageFilter && languageMember){
    let locateFilterObj = {
      member: languageMember,
      operator: "equals",
      values: [languageFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  return query
}

const DayEndSummaryQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  var result = {}
  let query1 = {
    ...OmnitechQueries.orders.OrderTotalDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.OrderTotalDayEnd.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEnd.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null)
  if(isRefresh)
    query1 = {}
  result["order"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.orders.VoidOrderTotalDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.VoidOrderTotalDayEnd.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEnd.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null)
  if(isRefresh)
    query2 = {}
  result["void_order"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  let query3 = {
    ...OmnitechQueries.orders.TipDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.TipDayEnd.query.timeDimensions.concat(
      {
        "dimension": "Orders.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query3 = applyFilter(query3, storeFilter, "Orders.shipmentStoreCode", null, null)
  if(isRefresh)
    query3 = {}
  result["tip"] = useCubeQuery(query3, {resetResultSetOnChange: true})

  let query4 = {
    ...OmnitechQueries.orders.VoidItemDayEnd.query,
    "timeDimensions": OmnitechQueries.orders.VoidItemDayEnd.query.timeDimensions.concat(
      {
        "dimension": "CartLinePropertiesDayEnd.softDeletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query4 = applyFilter(query4, storeFilter, "CartLinePropertiesDayEnd.storeCode", null, null)
  if(isRefresh)
    query4 = {}
  result["void_item"] = useCubeQuery(query4, {resetResultSetOnChange: true})

  let query5 = {
    ...OmnitechQueries.carts.OutstandingTableNetSales.query,
    "timeDimensions": [
      {
        "dimension": "Carts.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ]
  }
  query5 = applyFilter(query5, storeFilter, "Carts.storeCode", null, null)
  if(isRefresh)
    query5 = {}
  result["outstanding"] = useCubeQuery(query5, {resetResultSetOnChange: true})
  

  let query6 = {
    ...OmnitechQueries.orders.OrderTotalDayEndEM.query,
    "timeDimensions": OmnitechQueries.orders.OrderTotalDayEndEM.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEndEM.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query6 = applyFilter(query6, storeFilter, "OrderDayEndEM.shipmentStoreCode", null, null)
  if(isRefresh)
    query6 = {}
  result["average"] = useCubeQuery(query6, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return result
}

const NetSalesByStoreEMQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.dailyOperationPrint.netSalesByStoreEM.name'),
    tableHeaders: {
      "OrderDayEnd.count": { name: t('page.fnb.dailyOperationPrint.netSalesByStoreEM.tableHeader.count'), type: "number" },
      "OrderDayEnd.netSales":  { name: t('page.fnb.dailyOperationPrint.netSalesByStoreEM.tableHeader.netSales'), type: "number" },
      "OrderDayEnd.shipmentStoreCode":  { name: t('page.fnb.dailyOperationPrint.netSalesByStoreEM.tableHeader.shipFromStoreCode'), type: "string" },
      "OrderDayEnd.orderSource":  { name: t('page.fnb.dailyOperationPrint.netSalesByStoreEM.tableHeader.orderSource'), type: "string" }
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'net_sales_by_store_exclude_membership_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.NetSalesByStoreEM.query,
    "timeDimensions": [
      {
        "dimension": "OrderDayEnd.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderDayEnd.shipmentStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const PaymentByStoreTypeQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let item = {
    name: t('page.fnb.dailyOperationPrint.paymentByStoreType.name'),
    tableHeaders: {
      "PaymentRequests.sumAmount": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.sumAmount'), type: "number" },
      "PaymentRequests.sumTip": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.sumTip'), type: "number" },
      "PaymentRequests.sumGrandTotal": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.grandTotal'), type: "number" },
      "Orders.count": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.countOrderId'), type: "number" },
      "Orders.shipmentStoreCode": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.shipmentsFromStoreCode'), type: "string" },
      "PaymentProviders.code": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.name'), type: "string" },
      "Currencies.code": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.currencyCode'), type: "string" },
      "PaymentRequests.state": { name: t('page.fnb.dailyOperationPrint.paymentByStoreType.tableHeader.state'), type: "string" },
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'payment_by_store_and_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.PaymentByStoreType.query,
    "timeDimensions": [
      {
        "dimension": "Orders.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "Orders.shipmentStoreCode", languageFilter, "PaymentProviderTranslations.locale")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }

  let item = {
    name: t('page.fnb.dailyOperationPrint.discountBreakdown.name'),
    tableHeaders: {
      "OrderTransactionsDiscount.storeCode": { name: t('page.fnb.dailyOperationPrint.discountBreakdown.tableHeader.shipFromStoreCode'), type: 'string'},
      "OrderTransactionsDiscount.discountCode": { name: t('page.fnb.dailyOperationPrint.discountBreakdown.tableHeader.discountCode'), type: 'string'},
      "OrderTransactionsDiscount.discountName": { name: t('page.fnb.dailyOperationPrint.discountBreakdown.tableHeader.discountName'), type: 'string'},
      "OrderTransactionsDiscount.totalAmount": { name: t('page.fnb.dailyOperationPrint.discountBreakdown.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsDiscount.sumQuantity": { name: t('page.fnb.dailyOperationPrint.discountBreakdown.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'discount_breakdown_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.DiscountBreakdown.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsDiscount.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", languageFilter, "OrderTransactionsDiscount.discountLocale")
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipSalesByTypeQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.name'),
    tableHeaders: {
      "OrderTransactionsWOPayment.shipFromStoreCode": { name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.tableHeader.shipFromStoreCode'), type: 'string'},
      "OrderTransactionsWOPayment.skuCode": { name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.tableHeader.skuCode'), type: 'string'},
      "OrderTransactionsWOPayment.skuInternalUseTitle": { name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.tableHeader.skuInternalUseTitle'), type: 'string'},
      "OrderTransactionsWOPayment.totalAmount": { name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsWOPayment.sumQuantity": { name: t('page.fnb.dailyOperationPrint.MembershipSalesByType.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_sales_by_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.MembershipSalesByType.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsWOPayment.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsWOPayment.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipTenderSummariesQuery = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.dailyOperationPrint.MembershipTenderSummaries.name'),
    tableHeaders: {
      "OrderTransactionsSales.shipFromStoreCode": { name: t('page.fnb.dailyOperationPrint.MembershipTenderSummaries.tableHeader.shipFromStoreCode'), type: 'string'},
      "PaymentProviders.code": { name: t('page.fnb.dailyOperationPrint.MembershipTenderSummaries.tableHeader.paymentProviderCode'), type: 'string'},
      "OrderTransactionsSales.totalAmount": { name: t('page.fnb.dailyOperationPrint.MembershipTenderSummaries.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsSales.sumQuantity": { name: t('page.fnb.dailyOperationPrint.MembershipTenderSummaries.tableHeader.quantity'), type: 'number'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'membership_tender_summaries_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.MembershipTenderSummaries.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsSales.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsSales.shipFromStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DailyOperationPrintPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const item3 = {grid: {xs: 3}}
  const item6 = {grid: {xs: 6}}
  const [rowsPerPage1, setRowsPerPage1] = useState(50);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(50);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(250);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(50);
  const [page4, setPage4] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(50);
  const [page5, setPage5] = useState(0);

  const getTotalSalesPercentageChart = (resultSet) => {  
    if (!resultSet) return {}
    
    const includedKey = [
      'OrderDayEnd.foodPercentage',
      'OrderDayEnd.beveragePercentage',
      'OrderDayEnd.otherPercentage',
    ]
    const dataSeries = resultSet?.seriesNames()
      .filter((seriesName) => {
        return includedKey.indexOf(seriesName.key) !== -1
      });
    const data = resultSet?.chartPivot()[0];
    return {
      data: dataSeries
        .map((seriesName, index) => {
          // only support number for pie chart data, converting the string to number 
          // by adding a "+" sign at beginning
          return {
            x: data[seriesName.key],
            name: t(`page.fnb.dailyOperationPrint.totalGrossSalesChart.labels.${seriesName.key}`),
          }
        }),
      dataSeries,
    }
  }

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);
  const [ refresh4, setRefresh4 ] = useState(false);
  const [ refresh5, setRefresh5 ] = useState(false);
  const [ refresh6, setRefresh6 ] = useState(false);

  const resultSets = DayEndSummaryQueries(storeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query1 = NetSalesByStoreEMQuery(storeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const query2 = PaymentByStoreTypeQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh3, setRefresh3)
  const query3 = DiscountBreakdownQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, refresh4, setRefresh4)
  const query4 = MembershipSalesByTypeQuery(storeFilter, startDateFilter, endDateFilter, refresh5, setRefresh5)
  const query5 = MembershipTenderSummariesQuery(storeFilter, startDateFilter, endDateFilter, refresh6, setRefresh6)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setRefresh4(true)
    setRefresh5(true)
    setRefresh6(true)
    setPage1(0)
    setPage2(0)
    setPage3(0)
    setPage4(0)
    setPage5(0)
  }

  // cache the chart massaged data
  const orderResultSet = resultSets["order"]?.resultSet
  const totalSalesPercentageChart = useMemo(() => {
    if (orderResultSet === null) return {data: [], dataSeries: []}
    
    return getTotalSalesPercentageChart(orderResultSet)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderResultSet, i18n.language])

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.dailyOperationPrint')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.dailyOperationPrint.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        pageChangerArr={[setPage1,setPage2,setPage3,setPage4,setPage5]} 
        dateLabel={t('page.fnb.dailyOperationPrint.filter.orderCompletedAt.date')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('page.fnb.dailyOperationPrint.text.salesBalances')} xs={3} lg={3} transparent={true}/>
      <TextComponent title={t('page.fnb.dailyOperationPrint.text.OperatingStatistics')} xs={6} lg={6} transparent={true}/>
      <TextComponent title={t('page.fnb.dailyOperationPrint.text.auditTrail')} xs={3} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalGrossSales')}
        fieldName="OrderDayEnd.grossSales" />
      <NumberComponent item={item6} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalNetSales')}
        fieldName="OrderDayEnd.netSales" />
      <NumberComponent item={item3} query={resultSets["void_order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalVoidOrder')}
        fieldName="OrderDayEnd.count" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalMembershipSales')}
        fieldName="OrderDayEnd.membershipCharges" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalOrder')}
        fieldName="OrderDayEndEM.count" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.avgBillAmount')}
        fieldName="OrderDayEndEM.avgBillAmount" />
      <NumberComponent item={item3} query={resultSets["void_order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalVoidOrderAmount')}
        fieldName="OrderDayEnd.orderTotal" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalDiscounts')}
        fieldName="OrderDayEnd.discounts" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalPax')}
        fieldName="OrderDayEndEM.pax" />
      <NumberComponent item={item3} query={resultSets["average"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.perPersonSpending')}
        fieldName="OrderDayEndEM.perPersonSpending" />
      <NumberComponent item={item3} query={resultSets["void_item"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalVoidItem')}
        fieldName="CartLinePropertiesDayEnd.sumCachedCalculationQuantity" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalServiceCharges')}
        fieldName="OrderDayEnd.serviceCharges" />
      <TextComponent title={t('page.fnb.dailyOperationPrint.text.salesBreakdown')} 
        xs={6} lg={6} transparent={true} styles={{marginTop: "60px"}}/>
      <NumberComponent item={item3} query={resultSets["tip"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalTip')}
        fieldName="PaymentRequests.sumTip" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalShippingFees')}
        fieldName="OrderDayEnd.shippingFees" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalFoodGrossSales')}
        fieldName="OrderDayEnd.foodGrossSales" />
      <NumberComponent item={item3} query={resultSets["order"]} percentage={true}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.foodPercentage')}
        fieldName="OrderDayEnd.foodPercentage" />
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalOrderAmountRounding')}
        fieldName="OrderDayEnd.orderAmountRounding" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalBeverageGrossSales')}
        fieldName="OrderDayEnd.beverageGrossSales" />
      <NumberComponent item={item3} query={resultSets["order"]} percentage={true}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.beveragePercentage')}
        fieldName="OrderDayEnd.beveragePercentage" />
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalNetSales')}
        fieldName="OrderDayEnd.netSales" />
      <NumberComponent item={item3} query={resultSets["order"]}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalOtherGrossSales')}
        fieldName="OrderDayEnd.otherGrossSales" />
      <NumberComponent item={item3} query={resultSets["order"]} percentage={true}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.otherPercentage')}
        fieldName="OrderDayEnd.otherPercentage" />
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>
      <ChartComponent 
        chartType="pie" 
        item={item6} 
        result={resultSets["order"]}
        data={totalSalesPercentageChart.data}
        dataSeries={totalSalesPercentageChart.dataSeries}
        titleName={t('page.fnb.dailyOperationPrint.titleMapping.totalGrossSalesChart')}
        progressType="circular"
        enableTooltip={false}
        percentage
      />  
      <TextComponent title={""} xs={12} lg={3} transparent={true}/>

      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
      <TextComponent title={t('page.fnb.dailyOperationPrint.text.tenderWarning')} xs={12} lg={12}/>
      <TableComponent item={query4.item} result={query4.result} rowsPerPage={rowsPerPage4} 
        setRowsPerPage={setRowsPerPage4} page={page4} setPage={setPage4}/>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.vsm'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.msm'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.CV'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.CU'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.aem'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.CASH_HKD'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.octopus'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.alipay_hkd_manual'),
        t('page.fnb.dailyOperationPrint.text.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} lg={12}/>
      <TableComponent item={query5.item} result={query5.result} rowsPerPage={rowsPerPage5} 
        setRowsPerPage={setRowsPerPage5} page={page5} setPage={setPage5}/>
    </Dashboard>
  ]) 
};

export default DailyOperationPrintPage;