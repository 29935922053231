import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { useTranslation } from 'react-i18next';
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, propertyAcFilter, storeCodeFilter, categoryCodesFilter, 
    propertyAcMember, storeCodeMember, categoryCodesMember) => {
  let propertyAcFilterObj = {
    member: propertyAcMember,
    operator: "contains",
    values: propertyAcFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(propertyAcFilterObj)
  }

  let storeCodeFilterObj = {
    member: storeCodeMember,
    operator: "equals",
    values: storeCodeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }


  let categoryCodesFilterObj = {
    member: categoryCodesMember,
    operator: "contains",
    values: [categoryCodesFilter]
  }


  if (categoryCodesFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(categoryCodesFilterObj)
    }
  }


  return query
}


const CurrentInventoryBalanceQuery = (propertyAcFilter, storeCodeFilter, categoryCodesFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.supplier.currentInventoryBalancePage.name'),
    tableHeaders: {
      "CurrentInventoryBalance.propertyAc":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.propertyAc'), type: "string" },
      "CurrentInventoryBalance.skuCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.skuCode'), type: "string" },
      "CurrentInventoryBalance.upcCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.upcCode'), type: "string" },
      "CurrentInventoryBalance.skuInternalUseTitle":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.skuInternalUseTitle'), type: "string" },
      "CurrentInventoryBalance.productCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productCode'), type: "string" },
      "CurrentInventoryBalance.productTitleEn":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productTitleEn'), type: "string" },
      "CurrentInventoryBalance.productTitleZh":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productTitleZh'), type: "string" },
      "CurrentInventoryBalance.categoryCodes":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.categoryCodes'), type: "string" },
      "CurrentInventoryBalance.storeCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.storeCode'), type: "string" },
      "CurrentInventoryBalance.stockCounterCacheId":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.stockCounterCacheId'), type: "number" },
      "CurrentInventoryBalance.availableForSale":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.availableForSale'), type: "number" },
      "CurrentInventoryBalance.instock":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.instock'), type: "number" },
      "CurrentInventoryBalance.holdCount":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.holdCount'), type: "number" },
      "CurrentInventoryBalance.available":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.available'), type: "number" },
      "CurrentInventoryBalance.cost":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.cost'), type: "number" },
      "CurrentInventoryBalance.inventoryCost":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.inventoryCost'), type: "number" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'current_inventory_balance_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.stockCounterCaches.CurrentInventoryBalance.query
  }
  if(isRefresh)
    query = {}
  query = applyFilter(query, propertyAcFilter, storeCodeFilter, categoryCodesFilter,
    "CurrentInventoryBalance.propertyAc", "CurrentInventoryBalance.storeCode", "CurrentInventoryBalance.categoryCodes" )
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CurrentInventoryMeasureBalance = (propertyAcFilter, storeCodeFilter, categoryCodesFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.supplier.currentInventoryBalancePage.measureName'),
    tableHeaders: {
      "CurrentInventoryMeasureBalance.propertyAc":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.propertyAc'), type: "string" },
      "CurrentInventoryMeasureBalance.skuCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.skuCode'), type: "string" },
      "CurrentInventoryMeasureBalance.upcCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.upcCode'), type: "string" },
      "CurrentInventoryMeasureBalance.skuInternalUseTitle":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.skuInternalUseTitle'), type: "string" },
      "CurrentInventoryMeasureBalance.productCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productCode'), type: "string" },
      "CurrentInventoryMeasureBalance.productTitleEn":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productTitleEn'), type: "string" },
      "CurrentInventoryMeasureBalance.productTitleZh":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.productTitleZh'), type: "string" },
      "CurrentInventoryMeasureBalance.categoryCodes":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.categoryCodes'), type: "string" },
      "CurrentInventoryMeasureBalance.storeCode":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.storeCode'), type: "string" },
      "CurrentInventoryMeasureBalance.balance":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.balance'), type: "number" },
      "CurrentInventoryMeasureBalance.unit":  { name: t('page.supplier.currentInventoryBalancePage.tableHeader.unit'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'current_inventory_balance_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.stockCounterCaches.CurrentInventoryMeasureBalance.query
  }
  if(isRefresh)
    query = {}
  query = applyFilter(query, propertyAcFilter, storeCodeFilter, categoryCodesFilter,
    "CurrentInventoryMeasureBalance.propertyAc", "CurrentInventoryMeasureBalance.storeCode", "CurrentInventoryMeasureBalance.categoryCodes")
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const CurrentInventoryBalancePage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let propertyAcSelect = []
  if(context.propertyAc)
    propertyAcSelect = context.propertyAc
  const [ propertyAcFilter, setPropertyAcFilter ] = useState([]);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  const [ categoryCodesFilter, setCategoryCodesFilter ] = useState('');
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const query1  = CurrentInventoryBalanceQuery(propertyAcFilter, storeCodeFilter, categoryCodesFilter, refresh1, setRefresh1)
  const query2  = CurrentInventoryMeasureBalance(propertyAcFilter, storeCodeFilter, categoryCodesFilter, refresh2, setRefresh2)

  const refresh = () => {
    setRefresh1(true)
    setPage1(0)
    setRefresh2(true)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.currentInventoryBalance')} />,
    <Dashboard>
      {!(propertyAcSelect.length === 0 || (propertyAcSelect.length === 1 && propertyAcSelect[0] === 'ALL_PROPRTTY_AC')) && 
      <MultiSelectComponent id={"property_ac_filter"} state={propertyAcFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setPropertyAcFilter} textLabel={t('page.supplier.currentInventoryBalancePage.filter.propertyAc.name')} selectArray={propertyAcSelect}/>
      }
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage1,setPage2]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.supplier.currentInventoryBalancePage.filter.storeCode.name')} selectArray={storeCodeSelect}/>
      <TextFieldComponent id={"category_codes_filter"} state={categoryCodesFilter} pageChangerArr={[setPage1,setPage2]} 
        stateChanger={setCategoryCodesFilter} textLabel={t('page.supplier.currentInventoryBalancePage.filter.categoryCodes')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default CurrentInventoryBalancePage;