import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, languageFilter, rsmCodeFilter, skuCodeFilter) => {
  let storeCodeFilterObj = {
    member: "SKUSalesTransactions.shipFromStoreCode",
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let locateFilterObj = {
    member: "ProductTranslations.locale",
    operator: "equals",
    values: [languageFilter]
  }
  if (languageFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  if(rsmCodeFilter.length>0){
    let rsmCodeFilterObj = {
      member: "ViewReportCategoryProductsFlatten.code",
      operator: "equals",
      values: rsmCodeFilter
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(rsmCodeFilterObj)
    }
  }

  if(skuCodeFilter){
    let skuCodeFilterObj = {
      member: "SKUSalesTransactions.skuCode",
      operator: "contains",
      values: [skuCodeFilter]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(skuCodeFilterObj)
    }
  }

  return query
}

const SKUSalesDetailsQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, rsmCodeFilter, skuCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.SKUSalesDetailsPage.name'),
    tableHeaders: {
      "SKUSalesTransactions.shipFromStoreCode": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.shipFromStoreCode'), type: 'string'},
      "SKUSalesTransactions.skuCode": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.skuCode'), type: 'string'},
      "SKUSalesTransactions.skuInternalUseTitle": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.skuInternalUseTitle'), type: 'string'},
      "SKUSalesTransactions.skuCost": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.skuCost'), type: 'number'},
      "ProductTranslations.title": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.productTitle'), type: 'string'},
      "ViewReportCategoryProductsFlatten.code":  { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.code'), type: "string" },
      "SKUSalesTransactions.totalAmount": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.lineTotal'), type: 'number'},
      "SKUSalesTransactions.sumQuantity": { name: t('page.fnb.SKUSalesDetailsPage.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'sku_sales_details_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.SKUSalesDetails.query,
    "timeDimensions": [
      {
        "dimension": "SKUSalesTransactions.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, languageFilter, rsmCodeFilter, skuCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const SKUSalesDetailsPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let rsmCodeSelect = ["a_la_carte", "afternoon_set", "alcohol_cocktail", "alcohol_mega", "appetizers", "baijiu", "bar_beverage", "beer", "breakfast", "brunch_set", "bubbles", "cash_voucher", "chicken", "coffee_tea", "create_your_own", "daily_special", "deliveroo", "deliveroo_brainfreeze", "deposit", "dessert", "dinner_set", "dinner_tasting_set", "eatigo", "exp_menu", "fathers_day_set", "festival_sets", "festive_menu", "festive_products", "festive_set", "food_modifiers", "foodpanda", "fruit_juice", "gin", "grand_open_party", "handcraft_burgers", "happy_hour", "hh_alcoholic_drink", "hh_snack_food", "hktvmall", "inline_spu", "k11", "k11tst_app_spu", "kids_menu", "kids_menu_drinks_upgrade", "kids_set", "liqueurs", "lunch_addon_drinks", "lunch_free_drinks", "lunch_refill_drinks", "lunch_set", "lunchset", "lunchset_addon", "membership", "merchant", "monthly_special", "mothers_day_set", "non_alcohol_cocktail", "non_alcohol_mega", "open_items", "openrice_spu", "other", "others_kk", "others_rz", "party", "partyset", "pasta", "premium_burgers", "premium_special", "privilege_pass", "quesadillas", "red_wine", "refill_drinks", "report_sales_mix", "retail", "ribs", "rose", "rsm_prom_leaflet", "rt_spu", "rte_tea_bogo", "ruby_mins", "rum", "salads_and_soups", "seafood", "seasonal_menu", "set_menu_2", "sides", "soft_beverage", "special_menu", "staff_meal", "staff_set", "steaks", "takeaway", "tea_free_drink", "tea_sets", "tequila", "tg_cafe", "valentines_set", "vodka", "voucher", "weekend_set", "whiskey", "white_wine", "wtc_app_spu", "xmas_set", "zeek_delivery"]
  const [rsmCodeFilter, setRsmCodeFilter] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [skuCodeFilter, setSkuCodeFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = SKUSalesDetailsQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, rsmCodeFilter, skuCodeFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.skuSalesDetails')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.SKUSalesDetailsPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
        dateLabel={t('page.fnb.SKUSalesDetailsPage.filter.orderCompletedAt.date')}/>
      <MultiSelectComponent id={"rsm_code_filter"} state={rsmCodeFilter} pageChangerArr={[setPage]}
          stateChanger={setRsmCodeFilter} textLabel={t('page.fnb.SKUSalesDetailsPage.filter.rsmCode')} selectArray={rsmCodeSelect}/>
      <TextFieldComponent id={"sku_code_filter"} state={skuCodeFilter} pageChangerArr={[setPage]} 
        stateChanger={setSkuCodeFilter} textLabel={t('page.fnb.SKUSalesDetailsPage.filter.skuCode')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default SKUSalesDetailsPage;