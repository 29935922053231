import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextComponent from '../../components/TextComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, referenceNumberFilter) => {
  let storeCodeFilterObj = {
    member: "OrderTransactionsAudit.shipFromStoreCode",
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let referenceNumberFilterObj = {
    member: "OrderTransactionsAudit.referenceNumber",
    operator: "contains",
    values: [referenceNumberFilter]
  }


  if (referenceNumberFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(referenceNumberFilterObj)
    }
  }
  return query
}

const OrderEntriesAuditTrailQueries = (storeFilter, startDateFilter, endDateFilter, referenceNumberFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.fnb.orderEntriesAuditTrail.name'),
    tableHeaders: {
      "OrderTransactionsAudit.orderId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderId'), type: "string" },
      "OrderTransactionsAudit.otGroupId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.otGroupId'), type: "string" },
      "OrderTransactionsAudit.lv1Ordering": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.lv1Ordering'), type: "string" },
      "OrderTransactionsAudit.lv2Ordering": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.lv2Ordering'), type: "string" },
      "OrderTransactionsAudit.referenceNumber": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.referenceNumber'), type: "string" },
      "OrderTransactionsAudit.storeId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.storeId'), type: "string" },
      "OrderTransactionsAudit.testOrder": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.testOrder'), type: "boolean" },
      "OrderTransactionsAudit.shipmentStoreId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.shipmentStoreId'), type: "string" },
      "OrderTransactionsAudit.shipFromStoreCode": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.shipmentStoreCode'), type: "string" },
      "OrderTransactionsAudit.commerceChannelCode": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.commerceChannelsCode'), type: "string" },
      "OrderTransactionsAudit.orderState": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderState'), type: "string" },
      "OrderTransactionsAudit.orderCompletedAt": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderCompletedAt'), type: "dateTime" },
      "OrderTransactionsAudit.cartCreatedAt": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.cartCreatedAt'), type: "dateTime" },
      "OrderTransactionsAudit.cartClosedAt": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.cartClosedAt'), type: "dateTime" },
      "OrderTransactionsAudit.orderTransactionsId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderTransactionsId'), type: "string" },
      "OrderTransactionsAudit.internalAppOperatorId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.internalAppOperatorsId'), type: "string" },
      "OrderTransactionsAudit.internalAppOperatorStaffNumber": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.staffNumber'), type: "string" },
      "OrderTransactionsAudit.internalAppOperatorFirstName": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.firstName'), type: "string" },
      "OrderTransactionsAudit.internalAppOperatorLastName": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.lastName'), type: "string" },
      "OrderTransactionsAudit.unitAmount": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.unitAmount'), type: "number" },
      "OrderTransactionsAudit.quantity": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.quantity'), type: "number" },
      "OrderTransactionsAudit.lineTotal": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.lineTotal'), type: "number" },
      "OrderTransactionsAudit.code": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderTransactionsCode'), type: "string" },
      "OrderTransactionsAudit.calculateUnitAmountTargetType": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.calculateUnitAmountTargetType'), type: "string" },
      "OrderTransactionsAudit.calculateUnitAmountTargetId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.calculateUnitAmountTargetId'), type: "string" },
      "OrderTransactionsAudit.couponTokenId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.couponTokensId'), type: "string" },
      "OrderTransactionsAudit.couponCode": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.couponsCode'), type: "string" },
      "OrderTransactionsAudit.skuCode": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.skuCode'), type: "string" },
      "OrderTransactionsAudit.productTitleZh": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.productTitleZh'), type: "string" },
      "OrderTransactionsAudit.productTitleEn": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.productTitleEn'), type: "string" },
      "OrderTransactionsAudit.orderLinePropertyUserId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.cartLinePropertyFnbOperatorsId'), type: "string" },
      "OrderTransactionsAudit.orderLinePropertyStaffNumber": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderLinePropertyStaffNumber'), type: "string" },
      "OrderTransactionsAudit.olpOtId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderLinePropertyOrderTransactionsId'), type: "string" },
      "OrderTransactionsAudit.omcdolpId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderManualCartDiscountOrderLinePropertiesId'), type: "string" },
      "OrderTransactionsAudit.omcdId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.orderManualCartDiscountsId'), type: "string" },
      "OrderTransactionsAudit.omcdOrderLevelId": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.omcdOrderLevelId'), type: "string" },
      "OrderTransactionsAudit.itemCreatedAt": { name: t('page.fnb.orderEntriesAuditTrail.tableHeader.itemCreatedAt'), type: "dateTime" },
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'order_entries_audit_trail_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.OrderEntriesAuditTrail.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsAudit.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  if(isRefresh)
    query = {}
  query = applyFilter(query, storeFilter, referenceNumberFilter)
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const OrderEntriesAuditTrailPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  const [ referenceNumberFilter, setReferenceNumberFilter ] = useState('');
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result } = OrderEntriesAuditTrailQueries(storeFilter, startDateFilter, endDateFilter, referenceNumberFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.orderEntriesAuditTrailReport')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.orderEntriesAuditTrail.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]} 
        dateLabel={t('page.fnb.orderEntriesAuditTrail.filter.orderCompletedAt.date')}/>
      <TextFieldComponent id={"reference_number_filter"} state={referenceNumberFilter} pageChangerArr={[setPage]} 
        stateChanger={setReferenceNumberFilter} textLabel={t('page.fnb.orderEntriesAuditTrail.filter.referenceNumber')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.longQuery')} xs={12} />
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default OrderEntriesAuditTrailPage;