import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, languageFilter, discountCodeFilter) => {
  let storeCodeFilterObj = {
    member: "OrderTransactionsDiscount.storeCode",
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let locateFilterObj = {
    member: "OrderTransactionsDiscount.discountLocale",
    operator: "equals",
    values: [languageFilter]
  }
  if (languageFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }


  let discountCodeFilterObj = {
    member: "OrderTransactionsDiscount.discountCode",
    operator: "contains",
    values: [discountCodeFilter]
  }


  if (discountCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(discountCodeFilterObj)
    }
  }
  return query
}

const DiscountBreakdownByStaffQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, discountCodeFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.fnb.discountBreakdownByStaff.name'),
    tableHeaders: {
      "OrderTransactionsDiscount.storeCode": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.shipFromStoreCode'), type: 'string'},
      "InternalAppOperators.staffNumber": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.staffNumber'), type: 'string'},
      "InternalAppOperators.firstName": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.firstName'), type: 'string'},
      "InternalAppOperators.lastName": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.lastName'), type: 'string'},
      "OrderTransactionsDiscount.discountCode": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.discountCode'), type: 'string'},
      "OrderTransactionsDiscount.discountName": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.discountName'), type: 'string'},
      "OrderTransactionsDiscount.totalAmount": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsDiscount.sumQuantity": { name: t('page.fnb.discountBreakdownByStaff.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'discount_breakdown_by_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactionsDiscount.DiscountBreakdownByStaff.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsDiscount.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, languageFilter, discountCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownByStaffPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [ discountCodeFilter, setDiscountCodeFilter ] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = DiscountBreakdownByStaffQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, discountCodeFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.discountBreakdownByStaffReport')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.discountBreakdownByStaff.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} dateLabel={t('page.fnb.discountBreakdownByStaff.filter.orderCompletedAt.date')}/>
      <TextFieldComponent id={"discount_code_filter"} state={discountCodeFilter} pageChangerArr={[setPage]} 
        stateChanger={setDiscountCodeFilter} textLabel={t('page.fnb.discountBreakdownByStaff.filter.discountCode')}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default DiscountBreakdownByStaffPage;