import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, storeFilterMember, languageFilter, staffNumberFilter) => {
  let storeCodeFilterObj = {
    member: storeFilterMember,
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let locateFilterObj = {
    member: "OrderTransactionsDiscount.discountLocale",
    operator: "equals",
    values: [languageFilter]
  }
  if (languageFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  let staffNumberFilterObj = {
    member: "InternalAppOperators.staffNumber",
    operator: "contains",
    values: [staffNumberFilter]
  }

  if (staffNumberFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(staffNumberFilterObj)
    }
  }

  return query
}

const PaymentByStoreStaffQuery = (storeFilter, startDateFilter, endDateFilter, staffNumberFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.name'),
    tableHeaders: {
      "ShipmentStores.code": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.storeCode'), type: 'string'},
      "PaymentProviders.code": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.paymentProviderCode'), type: 'string'},
      "InternalAppOperators.staffNumber": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.staffNumber'), type: 'string'},
      "InternalAppOperators.firstName": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.firstName'), type: 'string'},
      "InternalAppOperators.lastName": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.lastName'), type: 'string'},
      "Orders.count": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.orderCount'), type: 'number'},
      "PaymentRequests.sumAmount": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.sumAmount'), type: 'number'},
      "PaymentRequests.sumTip": { name: t('page.fnb.staffTenderSummaryPage.paymentByStoreStaff.tableHeader.sumTip'), type: 'number'}
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'payment_by_store_and_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.PaymentByStoreStaff.query,
    "timeDimensions": [
      {
        "dimension": "Orders.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "ShipmentStores.code", null, staffNumberFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const DiscountBreakdownByStaffQuery = (storeFilter, startDateFilter, endDateFilter, languageFilter, staffNumberFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.name'),
    tableHeaders: {
      "OrderTransactionsDiscount.storeCode": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.shipFromStoreCode'), type: 'string'},
      "InternalAppOperators.staffNumber": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.staffNumber'), type: 'string'},
      "InternalAppOperators.firstName": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.firstName'), type: 'string'},
      "InternalAppOperators.lastName": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.lastName'), type: 'string'},
      "OrderTransactionsDiscount.discountCode": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.discountCode'), type: 'string'},
      "OrderTransactionsDiscount.discountName": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.discountName'), type: 'string'},
      "OrderTransactionsDiscount.totalAmount": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsDiscount.sumQuantity": { name: t('page.fnb.staffTenderSummaryPage.discountBreakdownByStaff.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'discount_breakdown_by_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactionsDiscount.DiscountBreakdownByStaff.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsDiscount.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsDiscount.storeCode", languageFilter, staffNumberFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipTenderDetailsByStaffQuery = (storeFilter, startDateFilter, endDateFilter, staffNumberFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.name'),
    tableHeaders: {
      "OrderTransactionsSales.sumQuantity": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.quantity'), type: 'number'},
      "OrderTransactionsSales.totalAmount": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.lineTotal'), type: 'number'},
      "OrderTransactionsSales.shipFromStoreCode": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.shipFromStoreCode'), type: 'string'},
      "PaymentProviders.code": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.paymentProviderCode'), type: 'string'},
      "InternalAppOperators.staffNumber": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.staffNumber'), type: 'string'},
      "InternalAppOperators.firstName": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.firstName'), type: 'string'},
      "InternalAppOperators.lastName": { name: t('page.fnb.staffTenderSummaryPage.membershipTenderDetailsByStaff.tableHeader.lastName'), type: 'string'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'membership_tender_details_by_staff_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderTransactions.MembershipTenderDetailsByStaff.query,
    "timeDimensions": [
      {
        "dimension": "OrderTransactionsSales.orderCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, "OrderTransactionsSales.shipFromStoreCode", null, staffNumberFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const StaffTenderSummaryPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  const [ staffNumberFilter, setStaffNumberFilter ] = useState('');
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const [page3, setPage3] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);
  const [ refresh3, setRefresh3 ] = useState(false);

  const query1 = PaymentByStoreStaffQuery(storeFilter, startDateFilter, endDateFilter, staffNumberFilter, refresh1, setRefresh1)
  const query2 = DiscountBreakdownByStaffQuery(storeFilter, startDateFilter, endDateFilter, languageFilter, staffNumberFilter, refresh2, setRefresh2)
  const query3 = MembershipTenderDetailsByStaffQuery(storeFilter, startDateFilter, endDateFilter, staffNumberFilter, refresh3, setRefresh3)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setRefresh3(true)
    setPage1(0)
    setPage3(0)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.staffTenderSummary')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.staffTenderSummaryPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <DatePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,setPage2,setPage3]} 
        dateLabel={t('page.fnb.staffTenderSummaryPage.filter.orderCompletedAt.date')}/>
      <TextFieldComponent id={"staff_number_filter"} state={staffNumberFilter} pageChangerArr={[setPage1,setPage2,setPage3]} 
          stateChanger={setStaffNumberFilter} textLabel={t('page.fnb.staffTenderSummaryPage.filter.staffNumber')}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.vsm'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.msm'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.CV'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.CU'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.aem'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.CASH_HKD'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.octopus'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.alipay_hkd_manual'),
        t('page.fnb.paymentByStoreTypeBreakdownPage.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} />
      <TableComponent item={query1.item} result={query1.result} rowsPerPage={rowsPerPage1} 
        setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={query2.item} result={query2.result} rowsPerPage={rowsPerPage2} 
        setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
      <TableComponent item={query3.item} result={query3.result} rowsPerPage={rowsPerPage3} 
        setRowsPerPage={setRowsPerPage3} page={page3} setPage={setPage3}/>
    </Dashboard>
  ]) 
};

export default StaffTenderSummaryPage;