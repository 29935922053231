import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import NumberComponent from '../../components/NumberComponent';
import TableComponent from '../../components/TableComponent';
import MultiNumberComponent from '../../components/MultiNumberComponent'
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns'

const applyFilter = (query, storeFilter, storeMember) => {
  var storeFilterObj = {
    member: storeMember,
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeFilterObj)
  }
  return query
}

const FSDayEndSummaryQueries = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  var result = {}
  let query1 = {
    ...OmnitechQueries.orders.FSOrderSummary.query,
    "timeDimensions": OmnitechQueries.orders.FSOrderSummary.query.timeDimensions.concat(
      {
        "dimension": "OrderDayEndFS.orderConfirmedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query1 = applyFilter(query1, storeFilter, "OrderDayEndFS.shipmentStoreCode", null, null)
  if(isRefresh)
    query1 = {}
  result["summary"] = useCubeQuery(query1, {resetResultSetOnChange: true})

  let query2 = {
    ...OmnitechQueries.orderRefundRequest.OrderRefundSummary.query,
    "timeDimensions": OmnitechQueries.orderRefundRequest.OrderRefundSummary.query.timeDimensions.concat(
      {
        "dimension": "OrderRefundSummary.refundedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query2 = applyFilter(query2, storeFilter, "OrderRefundSummary.shipmentStoreCode", null, null)
  if(isRefresh)
    query2 = {}
  result["refund"] = useCubeQuery(query2, {resetResultSetOnChange: true})

  if(isRefresh)
    setRefresh(false)
  return result
}

const OrderRefundSummaryDetail = (storeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  let item = {
    name: t('page.fs.dailyOperation.orderRefundSummaryDetail.name'),
    tableHeaders: {
      "OrderRefundSummary.id": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.id'), type: 'string'},
      "OrderRefundSummary.shipmentStoreCode": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.shipmentStoreCode'), type: 'string'},
      "OrderRefundSummary.commerceChannelCode": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.commerceChannelCode'), type: 'string'},
      "OrderRefundSummary.orderId": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.orderId'), type: 'number'},
      "OrderRefundSummary.referenceNumber": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.referenceNumber'), type: 'string'},
      "OrderRefundSummary.refundAmount": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.refundAmount'), type: 'number'},
      "OrderRefundSummary.refundedAt": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.refundedAt'), type: 'dateTime'},
      "OrderRefundSummary.refundState": { name: t('page.fs.dailyOperation.orderRefundSummaryDetail.tableHeader.refundState'), type: 'string'},
    },
    grid: { xs: 12 },
    export: { allowExport: true, filename: 'order_refund_summary_detail_export_' + format(startDateFilter, 'yyyyMMddHHmmss') +
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orderRefundRequest.OrderRefundSummaryDetail.query,
    "timeDimensions": OmnitechQueries.orderRefundRequest.OrderRefundSummaryDetail.query.timeDimensions.concat(
      {
        "dimension": "OrderRefundSummary.refundedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, storeFilter, "OrderRefundSummary.shipmentStoreCode", null, null)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const FSDailyOperationPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const resultSets = FSDayEndSummaryQueries(storeCodeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)
  const query = OrderRefundSummaryDetail(storeCodeFilter, startDateFilter, endDateFilter, refresh2, setRefresh2)
  const item = {grid: {xs: 6, md: 2}}
  const item2 = {grid: {xs: 12, md: 6}}
  const item3 = {grid: {xs: 12, md: 3}}
  const item4 = {grid: {xs: 12, md: 12}}

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.fsDailyOperation')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.fs.dailyOperation.filter.storeCode.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
      pageChangerArr={[setPage]}
      startDateLabel={t('page.fs.dailyOperation.filter.orderConfirmedAt.startDate')} 
      toLabel={t('page.fs.dailyOperation.filter.orderConfirmedAt.to')} 
      endDateLabel={t('page.fs.dailyOperation.filter.orderConfirmedAt.endDate')}/>
    </Dashboard>,
    <Dashboard>
      <NumberComponent item={item2} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.orderCount')}
        fieldName="OrderDayEndFS.count" />
      <NumberComponent item={item2} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.orderQuantity')}
        fieldName="OrderDayEndFS.totalQuantity" />
      <NumberComponent item={item2} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.netSales')}
        fieldName="OrderDayEndFS.netSales" />
      <NumberComponent item={item2} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.grossSales')}
        fieldName="OrderDayEndFS.grossSales" />
      <NumberComponent item={item2} query={resultSets["refund"]}
        titleName={t('page.fs.dailyOperation.titleMapping.totalRefund')}
        fieldName="OrderRefundSummary.count" />
      <NumberComponent item={item2} query={resultSets["refund"]}
        titleName={t('page.fs.dailyOperation.titleMapping.totalRefundAmount')}
        fieldName="OrderRefundSummary.totalRefundAmount" />

      <MultiNumberComponent item={item4} queries={[resultSets["summary"],resultSets["refund"]]}
        titleName={t('page.fs.dailyOperation.titleMapping.netSalesDeductRefund')}
        fieldNames={["OrderDayEndFS.netSales","OrderRefundSummary.totalRefundAmount"]}
        operators={["-"]} />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.dpGrossSales')}
        fieldName="OrderDayEndFS.dpGrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.dpTotalQuantity')}
        fieldName="OrderDayEndFS.dpTotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.dpDiscount')}
        fieldName="OrderDayEndFS.dpDiscount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.dpNetSales')}
        fieldName="OrderDayEndFS.dpNetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.dpPercentage')}
        fieldName="OrderDayEndFS.dpPercentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.giftGrossSales')}
        fieldName="OrderDayEndFS.giftGrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.giftTotalQuantity')}
        fieldName="OrderDayEndFS.giftTotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.giftDiscount')}
        fieldName="OrderDayEndFS.giftDiscount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.giftNetSales')}
        fieldName="OrderDayEndFS.giftNetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.giftPercentage')}
        fieldName="OrderDayEndFS.giftPercentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con1GrossSales')}
        fieldName="OrderDayEndFS.con1GrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con1TotalQuantity')}
        fieldName="OrderDayEndFS.con1TotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con1Discount')}
        fieldName="OrderDayEndFS.con1Discount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con1NetSales')}
        fieldName="OrderDayEndFS.con1NetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.con1Percentage')}
        fieldName="OrderDayEndFS.con1Percentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con2GrossSales')}
        fieldName="OrderDayEndFS.con2GrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con2TotalQuantity')}
        fieldName="OrderDayEndFS.con2TotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con2Discount')}
        fieldName="OrderDayEndFS.con2Discount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.con2NetSales')}
        fieldName="OrderDayEndFS.con2NetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.con2Percentage')}
        fieldName="OrderDayEndFS.con2Percentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.rec1GrossSales')}
        fieldName="OrderDayEndFS.rec1GrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.rec1TotalQuantity')}
        fieldName="OrderDayEndFS.rec1TotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.rec1Discount')}
        fieldName="OrderDayEndFS.rec1Discount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.rec1NetSales')}
        fieldName="OrderDayEndFS.rec1NetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.rec1Percentage')}
        fieldName="OrderDayEndFS.rec1Percentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.t2GrossSales')}
        fieldName="OrderDayEndFS.t2GrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.t2TotalQuantity')}
        fieldName="OrderDayEndFS.t2TotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.t2Discount')}
        fieldName="OrderDayEndFS.t2Discount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.t2NetSales')}
        fieldName="OrderDayEndFS.t2NetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.t2Percentage')}
        fieldName="OrderDayEndFS.t2Percentage" />

      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.otherGrossSales')}
        fieldName="OrderDayEndFS.otherGrossSales" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.otherTotalQuantity')}
        fieldName="OrderDayEndFS.otherTotalQuantity" />
      <NumberComponent item={item} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.otherDiscount')}
        fieldName="OrderDayEndFS.otherDiscount" />
      <NumberComponent item={item3} query={resultSets["summary"]}
        titleName={t('page.fs.dailyOperation.titleMapping.otherNetSales')}
        fieldName="OrderDayEndFS.otherNetSales" />
      <NumberComponent item={item} query={resultSets["summary"]} percentage={true}
        titleName={t('page.fs.dailyOperation.titleMapping.otherPercentage')}
        fieldName="OrderDayEndFS.otherPercentage" />

      <TableComponent item={query.item} result={query.result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default FSDailyOperationPage;