import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextComponent from '../../components/TextComponent';
import NumberComponent from '../../components/NumberComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import { CubeJSContext } from '../../context/CubeJSProvider';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns'

const applyFilter = (query, storeFilter, membershipCodeFilter, emailFilter, 
  phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter) => {
  
  if(channelFilter.length === 1){
  let channelFilterObj = {
      member: "MvOrderTransactions.commerceChannelCode",
      operator: "equals",
      values: channelFilter[0] === 'mobile' ? ['membership_upgrade'] : ['membership_upgrade_pos']
    }

    query = {
      ...query,
      filters: (query.filters || []).concat(channelFilterObj)
    }
  }

  
  if(channelFilter.length === 1 && channelFilter[0] === 'pos'){
    let storeCodeFilterObj = {
      member: "MvOrderTransactions.shipFromStoreCode",
      operator: "equals",
      values: (channelFilter.length === 1 && channelFilter[0] === 'mobile')? [] : storeFilter
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(storeCodeFilterObj)
    }
  }
  else if(channelFilter.length === 2 || channelFilter.length === 0){
    let storeCodeFilterObj = {
      or: [
        {
          member: "MvOrderTransactions.commerceChannelCode",
          operator: "equals",
          values: ['membership_upgrade']
        },
        {
          and: [
            {
              member: "MvOrderTransactions.commerceChannelCode",
              operator: "equals",
              values: ['membership_upgrade_pos']
            },
            {
              member: "MvOrderTransactions.shipFromStoreCode",
              operator: "equals",
              values: storeFilter
            }
          ]
        }
      ]
    }
    query = {
      ...query,
      filters: (query.filters || []).concat(storeCodeFilterObj)
    }
  }

  

  let membershipCodeFilterObj = {
    member: "Users.membershipCode",
    operator: "contains",
    values: [membershipCodeFilter]
  }


  if (membershipCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  let emailFilterObj = {
    member: "Users.email",
    operator: "contains",
    values: [emailFilter]
  }


  if (emailFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(emailFilterObj)
    }
  }

  let phoneFilterObj = {
    member: "Users.phone",
    operator: "contains",
    values: [phoneFilter]
  }


  if (phoneFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(phoneFilterObj)
    }
  }

  let userNameFilterObj = {
    member: "Users.firstName",
    operator: "contains",
    values: [userNameFilter]
  }


  if (userNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userNameFilterObj)
    }
  }

  let skuCodeFilterObj = {
    member: "MvOrderTransactions.skuCode",
    operator: "contains",
    values: [skuCodeFilter]
  }


  if (skuCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(skuCodeFilterObj)
    }
  }

  let skuTitleFilterObj = {
    member: "MvOrderTransactions.skuInternalUseTitle",
    operator: "contains",
    values: [skuTitleFilter]
  }


  if (skuTitleFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(skuTitleFilterObj)
    }
  }

  console.log(query)

  return query
}

const MembershipPurchaseExporterQuery = (storeFilter, startDateFilter, endDateFilter, membershipCodeFilter, 
  emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }
  let item = {
    name: t('page.membership.membershipPurchaseExporterPage.name'),
    tableHeaders: {
      "Users.membershipCode": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.membershipCode'), type: 'string'},
      "Users.firstName": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.userName'), type: 'string'},
      "Users.email": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.email'), type: 'string'},
      "Users.phone": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.phone'), type: 'string'},
      "MvOrdersSummary.referenceNumber": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.referenceNumber'), type: 'string'},
      "MvOrderTransactions.staffNumber": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.staffNumber'), type: 'string'},
      "MvOrderTransactions.firstName": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.firstName'), type: 'string'},
      "MvOrderTransactions.commerceChannelCode": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.commerceChannelCode'), type: 'string'},
      "MvOrderTransactions.skuCode": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.skuCode'), type: 'string'},
      "MvOrderTransactions.skuInternalUseTitle": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.skuInternalUseTitle'), type: 'string'},
      "MvOrderTransactions.productCode": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.productCode'), type: 'string'},
      "MvOrderTransactions.productTitleEnHk": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.productTitleEnHk'), type: 'string'},
      "MvOrderTransactions.productTitleZhHk": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.productTitleZhHk'), type: 'string'},
      "MvOrderTransactions.unitAmount": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.unitAmount'), type: 'number'},
      "MvOrderTransactions.ordersCompletedAt": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.ordersCompletedAt'), type: 'dateTime'},
      "MvOrderTransactions.shipFromStoreCode": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.shipFromStoreCode'), type: 'string'},
      "MvOrderTransactions.lineTotal": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.lineTotal'), type: 'number'},
      "MvOrderTransactions.quantity": { name: t('page.membership.membershipPurchaseExporterPage.tableHeader.quantity'), type: 'number'},
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'membership_purchase_exporter_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.mvOrderTransactions.MembershipPurchase.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter]
      }
    ],
  }
  query = applyFilter(query, storeFilter, membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const MembershipPurchaseSummaryQuery = (storeFilter, startDateFilter, endDateFilter, membershipCodeFilter, 
  emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter, isRefresh, setRefresh) => {
  if(startDateFilter == null && endDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  else if(startDateFilter == null){
    startDateFilter = setHours(endDateFilter, 0)
    startDateFilter = setMinutes(startDateFilter, 0)
    startDateFilter = setSeconds(startDateFilter, 0)
    startDateFilter = setMilliseconds(startDateFilter, 0)
  }
  else if (endDateFilter == null){
    endDateFilter = setHours(startDateFilter, 23)
    endDateFilter = setMinutes(endDateFilter, 59)
    endDateFilter = setSeconds(endDateFilter, 59)
    endDateFilter = setMilliseconds(endDateFilter, 999)
  }

  var result = {}

  let query1 = {
    ...OmnitechQueries.mvOrderTransactions.MembershipPurchaseSummary.query,
    "timeDimensions": [
      {
        "dimension": "MvOrderTransactions.ordersCompletedAt",
        "dateRange": [ startDateFilter, endDateFilter]
      }
    ],
  }
  query1 = applyFilter(query1, storeFilter, membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter)
  if(isRefresh)
    query1 = {}
  result["membership"] = useCubeQuery(query1, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return result
}

const MembershipPurchaseExporterPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('');
  const [ emailFilter, setEmailFilter ] = useState('');
  const [ phoneFilter, setPhoneFilter ] = useState('');
  const [ userNameFilter, setUserNameFilter ] = useState('');
  const [ skuCodeFilter, setSkuCodeFilter ] = useState('');
  const [ skuTitleFilter, setSkuTitleFilter ] = useState('');
  const [ channelFilter, setChannelFilter ] = useState([]);
  let channelSelect = ['pos','mobile'];
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const [ page, setPage ] = useState(0);
  const item6 = { grid: { xs: 12, sm: 6, md: 6 }}

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const resultSets = MembershipPurchaseSummaryQuery(storeFilter, startDateFilter, endDateFilter, 
    membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter, refresh1, setRefresh1)
  const { item, result }  = MembershipPurchaseExporterQuery(storeFilter, startDateFilter, endDateFilter, 
    membershipCodeFilter, emailFilter, phoneFilter, userNameFilter, skuCodeFilter, skuTitleFilter, channelFilter, refresh2, setRefresh2)
    
  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.membershipPurchaseExporter')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage]}
          stateChanger={setStoreFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.store.name')} selectArray={storeCodeSelect}/>
      <MultiSelectComponent id={"channel_filter"} state={channelFilter} pageChangerArr={[setPage]}
          stateChanger={setChannelFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.channel.name')} selectArray={channelSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage]}
        startDateLabel={t('page.membership.membershipPurchaseExporterPage.filter.orderCompletedAt.startDate')} 
        toLabel={t('page.membership.membershipPurchaseExporterPage.filter.orderCompletedAt.to')} 
        endDateLabel={t('page.membership.membershipPurchaseExporterPage.filter.orderCompletedAt.endDate')}/>
      <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setMembershipCodeFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.membershipCode')}/>
      <TextFieldComponent id={"email_filter"} state={emailFilter} pageChangerArr={[setPage]}
        stateChanger={setEmailFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.email')}/>
      <TextFieldComponent id={"phone_filter"} state={phoneFilter} pageChangerArr={[setPage]}
        stateChanger={setPhoneFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.phone')}/>
      <TextFieldComponent id={"name_filter"} state={userNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserNameFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.userName')}/>
      <TextFieldComponent id={"sku_code_filter"} state={skuCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setSkuCodeFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.skuCode')}/>
      <TextFieldComponent id={"sku_title_filter"} state={skuTitleFilter} pageChangerArr={[setPage]}
        stateChanger={setSkuTitleFilter} textLabel={t('page.membership.membershipPurchaseExporterPage.filter.skuTitle')}/>
    </Dashboard>
    ,
    <Dashboard>
      <TextComponent title={t('component.text.historical')} xs={12} />
      <TextComponent title={t('component.text.mobileIgnoreStore')} xs={12} />
      <NumberComponent item={item6} query={resultSets["membership"]}
        titleName={t('page.membership.membershipPurchaseExporterPage.titleMapping.membershipSales')}
        fieldName="MvOrderTransactions.sumLineTotal" />
      <NumberComponent item={item6} query={resultSets["membership"]}
        titleName={t('page.membership.membershipPurchaseExporterPage.titleMapping.quantity')}
        fieldName="MvOrderTransactions.sumQuantity" />
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ]) 
};

export default MembershipPurchaseExporterPage;