import React, { useState, useContext } from "react";
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import TextComponent from '../../components/TextComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { CubeJSContext } from '../../context/CubeJSProvider';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, storeFilter, languageFilter, paymentProviderCodeFilter, staffNumberFilter, orderStateFilter, paymentStateFilter) => {
   let storeCodeFilterObj = {
    member: "ShipmentStores.code",
    operator: "equals",
    values: storeFilter
  }

  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  let locateFilterObj = {
    member: "PaymentProviderTranslations.locale",
    operator: "equals",
    values: [languageFilter]
  }
  if (languageFilter){
    query = {
      ...query,
      filters: (query.filters || []).concat(locateFilterObj)
    }
  }

  let paymentProviderCodeFilterObj = {
    member: "PaymentProviders.code",
    operator: "contains",
    values: [paymentProviderCodeFilter]
  }
  if(paymentProviderCodeFilter && paymentProviderCodeFilter !== ''){
    query = {
      ...query,
      filters: (query.filters || []).concat(paymentProviderCodeFilterObj)
    }
  }

  let staffNumberFilterObj = {
    member: "InternalAppOperators.staffNumber",
    operator: "contains",
    values: [staffNumberFilter]
  }
  if(staffNumberFilter !== ''){
    query = {
      ...query,
      filters: (query.filters || []).concat(staffNumberFilterObj)
    }
  }


  if(orderStateFilter !== null && orderStateFilter.length > 0){
    let orderStateFilterObj = {
      member: "Orders.state",
      operator: "equals",
      values: orderStateFilter
    }

    query = {
      ...query,
      filters: (query.filters || []).concat(orderStateFilterObj)
    }
  }

  if(paymentStateFilter !== null && paymentStateFilter.length > 0){
    let paymentStateFilterObj = {
      member: "PaymentRequests.state",
      operator: "equals",
      values: paymentStateFilter
    }

    query = {
      ...query,
      filters: (query.filters || []).concat(paymentStateFilterObj)
    }
  }
  return query
}

const OrderSettlementByPaymentQueries = (storeFilter, startDateFilter, endDateFilter, languageFilter, paymentProviderCodeFilter, 
  staffNumberFilter, orderStateFilter, paymentStateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter == null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.name'),
    tableHeaders: {
      "ShipmentStores.code": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.shipmentStoresCode'), type: "string" },
      "Orders.referenceNumber": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.referenceNumber'), type: "string" },
      "Orders.createdAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.createdAt'), type: "dateTime" },
      "Orders.completedAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.completedAt'), type: "dateTime" },
      "Orders.confirmedAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.confirmedAt'), type: "dateTime" },
      "Orders.state": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.state'), type: "string" },
      "PaymentRequests.state": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.paymentState'), type: "string" },
      "Orders.testOrder": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.testOrder'), type: "boolean" },
      "PaymentRequests.received": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.received'), type: "number" },
      "PaymentRequests.tipOrCharge": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.tipOrCharge'), type: "number" },
      "PaymentRequests.amount": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.amount'), type: "number" },
      "Currencies.code": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.currenciesCode'), type: "string" },
      "PaymentProviders.code": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.paymentProviderCode'), type: "string" },
      "PaymentProviderTranslations.name": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.paymentProviderName'), type: "string" },
      "PaymentRequests.providerPaymentType": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.providerPaymentType'), type: "string" },
      "InternalAppOperators.staffNumber": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.staffNumber'), type: "string" },
      "InternalAppOperators.firstName": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.firstName'), type: "string" },
      "InternalAppOperators.lastName": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.lastName'), type: "string" },
      "CommerceChannels.code": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.commerceChannelsCode'), type: "string" },
      "Orders.holdAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.holdAt'), type: "dateTime" },
      "Orders.reservedAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.reservedAt'), type: "dateTime" },
      "Orders.declinedAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.declinedAt'), type: "dateTime" },
      "Orders.waitingForApprovalAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.waitingForApprovalAt'), type: "dateTime" },
      "Orders.cancelledAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.cancelledAt'), type: "dateTime" },
      "Orders.errorAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.errorAt'), type: "dateTime" },
      "Orders.voidInProgressAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.voidInProgressAt'), type: "dateTime" },
      "Orders.voidCompletedAt": { name: t('page.fnb.orderSettlement.orderSettlementByPaymentType.tableHeader.voidCompletedAt'), type: "dateTime" }
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'order_settlement_by_payment_type_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.OrderSettlementPaymentLevel.query,
    "timeDimensions": [
      {
        "dimension": "Orders.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, languageFilter, paymentProviderCodeFilter, staffNumberFilter, orderStateFilter, paymentStateFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OrderSettlementByOrderQueries = (storeFilter, startDateFilter, endDateFilter, paymentProviderCodeFilter, 
  staffNumberFilter, orderStateFilter, paymentStateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  if(startDateFilter === null){
    startDateFilter = new Date()
    startDateFilter.setHours(0,0,0,0);
    endDateFilter = new Date()
    endDateFilter.setHours(23,59,59,999);
  }
  
  let item = {
    name: t('page.fnb.orderSettlement.orderSettlementByOrder.name'),
    tableHeaders: {
      "ShipmentStores.code": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.shipmentStoresCode'), type: "string" },
      "Orders.referenceNumber": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.referenceNumber'), type: "string" },
      "Orders.createdAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.createdAt'), type: "dateTime" },
      "Orders.completedAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.completedAt'), type: "dateTime" },
      "Orders.confirmedAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.confirmedAt'), type: "dateTime" },
      "Orders.state": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.state'), type: "string" },
      "PaymentRequests.state": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.paymentState'), type: "string" },
      "Orders.testOrder": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.testOrder'), type: "boolean" },
      "PaymentRequests.sumReceived": { name:  t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.received'), type: "number" },
      "PaymentRequests.sumTipOrCharge": { name:  t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.tipOrCharge'), type: "number" },
      "PaymentRequests.sumAmount": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.amount'), type: "number" },
      "Currencies.code": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.currenciesCode'), type: "string" },
      "Carts.referenceNumber": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.billNumber'), type: "string" },
      "InternalAppOperators.staffNumber": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.staffNumber'), type: "string" },
      "InternalAppOperators.firstName": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.firstName'), type: "string" },
      "InternalAppOperators.lastName": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.lastName'), type: "string" },
      "CommerceChannels.code": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.commerceChannelsCode'), type: "string" },
      "Orders.holdAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.holdAt'), type: "dateTime" },
      "Orders.reservedAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.reservedAt'), type: "dateTime" },
      "Orders.declinedAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.declinedAt'), type: "dateTime" },
      "Orders.waitingForApprovalAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.waitingForApprovalAt'), type: "dateTime" },
      "Orders.cancelledAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.cancelledAt'), type: "dateTime" },
      "Orders.errorAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.errorAt'), type: "dateTime" },
      "Orders.voidInProgressAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.voidInProgressAt'), type: "dateTime" },
      "Orders.voidCompletedAt": { name: t('page.fnb.orderSettlement.orderSettlementByOrder.tableHeader.voidCompletedAt'), type: "dateTime" }
    },
    grid: {xs: 12},
    export: { allowExport: true, filename: 'order_settlement_by_order_export_' + format(startDateFilter, 'yyyyMMddHHmmss') + 
      '_' + format(endDateFilter, 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.OrderSettlementOrderLevel.query,
    "timeDimensions": [
      {
        "dimension": "Orders.createdAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      }
    ],
  }
  query = applyFilter(query, storeFilter, null, paymentProviderCodeFilter, staffNumberFilter, orderStateFilter, paymentStateFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}


const OrderSettlementPage = () => {
  const { t, i18n } = useTranslation();
  let languageFilter = i18n.language
  if(languageFilter !== 'en-HK' && languageFilter !== 'zh-HK')
    languageFilter = 'en-HK'
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeFilter, setStoreFilter ] = useState([]);
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  let orderStateSelect = ['pending','hold','reserved','declined','waiting_for_approval',
    'confirmed','completed','cancelled','error','void_in_progress','void_completed']
  const [orderStateFilter, setOrderStateFilter] = useState(['completed']);
  let paymentStateSelect = ['pending','hook_received','authorized','failed',
    'refunded','completed','cancelled','error','void_in_progress','void_completed']
  const [paymentStateFilter, setPaymentStateFilter] = useState(['completed']);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [paymentProviderCodeFilter, setPaymentProviderCodeFilter] = useState('');
  const [staffNumberFilter, setStaffNumberFilter] = useState('');
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [page2, setPage2] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);
  const [ refresh2, setRefresh2 ] = useState(false);

  const orderSettlementByPaymentQueries = OrderSettlementByPaymentQueries(storeFilter, startDateFilter, endDateFilter, 
    languageFilter, paymentProviderCodeFilter, staffNumberFilter, orderStateFilter, paymentStateFilter, refresh1, setRefresh1)
  const orderSettlementByOrderQueries = OrderSettlementByOrderQueries(storeFilter, startDateFilter, endDateFilter, 
    paymentProviderCodeFilter, staffNumberFilter, orderStateFilter, paymentStateFilter, refresh2, setRefresh2)

  const refresh = () => {
    setRefresh1(true)
    setRefresh2(true)
    setPage1(0)
    setPage2(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.orderSettlementReport')} />,
    <Dashboard>
      <MultiSelectComponent id={"store_code_filter"} state={storeFilter} pageChangerArr={[setPage1,setPage2]}
          stateChanger={setStoreFilter} textLabel={t('page.fnb.orderSettlement.filter.store.name')} selectArray={storeCodeSelect}/>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} pageChangerArr={[setPage1,setPage2]}
        startDateLabel={t('page.fnb.orderSettlement.filter.orderCreatedAt.startDate')} 
        toLabel={t('page.fnb.orderSettlement.filter.orderCreatedAt.to')} 
        endDateLabel={t('page.fnb.orderSettlement.filter.orderCreatedAt.endDate')}/>
      <TextFieldComponent id={"payment_provider_code_filter"} state={paymentProviderCodeFilter} pageChangerArr={[setPage1]} 
        stateChanger={setPaymentProviderCodeFilter} textLabel={t('page.fnb.orderSettlement.filter.paymentProviderCode')}/>
      <TextFieldComponent id={"membership_code_filter"} state={staffNumberFilter} pageChangerArr={[setPage1,setPage2]} 
        stateChanger={setStaffNumberFilter} textLabel={t('page.fnb.orderSettlement.filter.staffNumber')}/>
      <MultiSelectComponent id={"order_state_filter"} state={orderStateFilter} pageChangerArr={[setPage1,setPage2]} 
        stateChanger={setOrderStateFilter} textLabel={t('page.fnb.orderSettlement.filter.orderState')} selectArray={orderStateSelect}/>
      <MultiSelectComponent id={"payment_state_filter"} state={paymentStateFilter} pageChangerArr={[setPage1,setPage2]} 
        stateChanger={setPaymentStateFilter} textLabel={t('page.fnb.orderSettlement.filter.paymentState')} selectArray={paymentStateSelect}/>
    </Dashboard>,
    <Dashboard>
      <TextComponent title={t('component.text.paymentBreakdownMapping')} 
        contentArray={[t('page.fnb.orderSettlement.paymentCodeMapping.vsm'),
        t('page.fnb.orderSettlement.paymentCodeMapping.msm'),
        t('page.fnb.orderSettlement.paymentCodeMapping.CV'),
        t('page.fnb.orderSettlement.paymentCodeMapping.CU'),
        t('page.fnb.orderSettlement.paymentCodeMapping.aem'),
        t('page.fnb.orderSettlement.paymentCodeMapping.CASH_HKD'),
        t('page.fnb.orderSettlement.paymentCodeMapping.octopus'),
        t('page.fnb.orderSettlement.paymentCodeMapping.alipay_hkd_manual'),
        t('page.fnb.orderSettlement.paymentCodeMapping.wechat_pay_hkd_manual')]} xs={12} />
      <TableComponent item={orderSettlementByPaymentQueries.item} result={orderSettlementByPaymentQueries.result}
        rowsPerPage={rowsPerPage1} setRowsPerPage={setRowsPerPage1} page={page1} setPage={setPage1}/>
      <TableComponent item={orderSettlementByOrderQueries.item} result={orderSettlementByOrderQueries.result}
        rowsPerPage={rowsPerPage2} setRowsPerPage={setRowsPerPage2} page={page2} setPage={setPage2}/>
    </Dashboard>
  ]) 
};

export default OrderSettlementPage;