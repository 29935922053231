import React, { useState, useContext } from "react";
import { CubeJSContext } from '../../context/CubeJSProvider';
import Header from '../../components/Header';
import TableComponent from '../../components/TableComponent';
import TextFieldComponent from '../../components/TextFieldComponent';
import MultiSelectComponent from '../../components/MultiSelectComponent';
import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import Dashboard from '../../components/Dashboard';
import PageHeaderComponent from '../../components/PageHeaderComponent';
import OmnitechQueries from '../../queries/OmnitechQueries'
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { format } from 'date-fns'

const applyFilter = (query, membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, storeCodeFilter) => {

  let membershipCodeFilterObj = {
    member: "OrderUsers.membershipCode",
    operator: "contains",
    values: [membershipCodeFilter]
  }


  if (membershipCodeFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(membershipCodeFilterObj)
    }
  }

  let emailFilterObj = {
    member: "OrderUsers.userEmail",
    operator: "contains",
    values: [emailFilter]
  }


  if (emailFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(emailFilterObj)
    }
  }

  let phoneFilterObj = {
    member: "OrderUsers.phone",
    operator: "contains",
    values: [phoneFilter]
  }


  if (phoneFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(phoneFilterObj)
    }
  }

  let userNameFilterObj = {
    member: "OrderUsers.firstName",
    operator: "contains",
    values: [userNameFilter]
  }


  if (userNameFilter !== '') {
    query = {
      ...query,
      filters: (query.filters || []).concat(userNameFilterObj)
    }
  }

  let storeCodeFilterObj = {
    member: "OrderUsers.shipmentStoreCode",
    operator: "equals",
    values: storeCodeFilter
  }
  query = {
    ...query,
    filters: (query.filters || []).concat(storeCodeFilterObj)
  }

  return query
}

const OrderUserExportQuery = (membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, storeCodeFilter, startDateFilter, endDateFilter, isRefresh, setRefresh) => {
  const { t } = useTranslation();
  let item = {
    name: t('page.fnb.orderUserExport.name'),
    tableHeaders: {
      "OrderUsers.referenceNumber":  { name: t('page.fnb.orderUserExport.tableHeader.referenceNumber'), type: "string" },
      "OrderUsers.shipmentStoreCode":  { name: t('page.fnb.orderUserExport.tableHeader.shipmentStoreCode'), type: "string" },
      "OrderUsers.completedAt":  { name: t('page.fnb.orderUserExport.tableHeader.completedAt'), type: "dateTime" },
      "OrderUsers.totalPrice":  { name: t('page.fnb.orderUserExport.tableHeader.totalPrice'), type: "number" },
      "OrderUsers.membershipCode":  { name: t('page.fnb.orderUserExport.tableHeader.membershipCode'), type: "string" },
      "OrderUsers.firstName":  { name: t('page.fnb.orderUserExport.tableHeader.firstName'), type: "string" },
      "OrderUsers.lastName":  { name: t('page.fnb.orderUserExport.tableHeader.lastName'), type: "string" },
      "OrderUsers.sex":  { name: t('page.fnb.orderUserExport.tableHeader.sex'), type: "string" },
      "OrderUsers.userEmail":  { name: t('page.fnb.orderUserExport.tableHeader.userEmail'), type: "string" },
      "OrderUsers.phone":  { name: t('page.fnb.orderUserExport.tableHeader.phone'), type: "string" },
      "OrderUsers.customerRankCode":  { name: t('page.fnb.orderUserExport.tableHeader.customerRankCode'), type: "string" },
      "OrderUsers.rankExpireAt":  { name: t('page.fnb.orderUserExport.tableHeader.rankExpireAt'), type: "dateTime" },
      "OrderUsers.userCreatedAt":  { name: t('page.fnb.orderUserExport.tableHeader.userCreatedAt'), type: "dateTime" },
      "OrderUsers.pointsBurn":  { name: t('page.fnb.orderUserExport.tableHeader.pointsBurn'), type: "number" },
      "OrderUsers.pointsEarn":  { name: t('page.fnb.orderUserExport.tableHeader.pointsEarn'), type: "number" },
      "OrderUsers.dineInNumberOfPeople":  { name: t('page.fnb.orderUserExport.tableHeader.dineInNumberOfPeople'), type: "number" },
      "OrderUsers.dineInDuration":  { name: t('page.fnb.orderUserExport.tableHeader.dineInDuration'), type: "number" },
      "OrderUsers.orderType":  { name: t('page.fnb.orderUserExport.tableHeader.orderType'), type: "string" },
      "OrderUsers.paymentMethod":  { name: t('page.fnb.orderUserExport.tableHeader.paymentMethod'), type: "string" },
    },
    grid: {xs: 12, lg: 12},
    export: { allowExport: true, filename: 'order_user_export_' + format(new Date(), 'yyyyMMddHHmmss') }
  }
  let query = {
    ...OmnitechQueries.orders.OrderUser.query,
    "timeDimensions": OmnitechQueries.orders.OrderUser.query.timeDimensions.concat(
      {
        "dimension": "OrderUsers.completedAt",
        "dateRange": [ startDateFilter, endDateFilter ]
      })
  }
  query = applyFilter(query, membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, storeCodeFilter)
  if(isRefresh)
    query = {}
  const result = useCubeQuery(query, {resetResultSetOnChange: true})
  if(isRefresh)
    setRefresh(false)
  return { item, result }
}

const OrderUserExportPage = () => {
  const { t } = useTranslation();
  const context = useContext(CubeJSContext);
  let storeCodeSelect = []
  if(context.storeCodes)
    storeCodeSelect = context.storeCodes
  const [ storeCodeFilter, setStoreCodeFilter ] = useState([]);
  const [ membershipCodeFilter, setMembershipCodeFilter ] = useState('');
  const [ emailFilter, setEmailFilter ] = useState('');
  const [ phoneFilter, setPhoneFilter ] = useState('');
  const [ userNameFilter, setUserNameFilter ] = useState('');
  let filterStartDate = new Date()
  filterStartDate.setHours(0,0,0,0);
  let filterEndDate = new Date()
  filterEndDate.setHours(23,59,59,999);
  const [dateRangeFilter, setDateRangeFilter] = useState([filterStartDate, filterEndDate])
  const [startDateFilter, endDateFilter] = dateRangeFilter
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [ refresh1, setRefresh1 ] = useState(false);

  const { item, result }  = OrderUserExportQuery(membershipCodeFilter, emailFilter, phoneFilter, 
    userNameFilter, storeCodeFilter, startDateFilter, endDateFilter, refresh1, setRefresh1)

  const refresh = () => {
    setRefresh1(true)
    setPage(0)
  }

  return ([
    <Header />,
    <PageHeaderComponent onClickFunc={refresh} refreshlabel={t('component.refresh')} headerTitle={t('component.header.menu.orderUserExport')} />,
    <Dashboard>
      <DateRangePickerComponent state={dateRangeFilter} stateChanger={setDateRangeFilter} 
      pageChangerArr={[setPage]}
      startDateLabel={t('page.fnb.orderUserExport.filter.orderCompletedAt.startDate')} 
      toLabel={t('page.fnb.orderUserExport.filter.orderCompletedAt.to')} 
      endDateLabel={t('page.fnb.orderUserExport.filter.orderCompletedAt.endDate')}/>
      <TextFieldComponent id={"membership_code_filter"} state={membershipCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setMembershipCodeFilter} textLabel={t('page.fnb.orderUserExport.filter.membershipCode')}/>
      <TextFieldComponent id={"email_filter"} state={emailFilter} pageChangerArr={[setPage]}
        stateChanger={setEmailFilter} textLabel={t('page.fnb.orderUserExport.filter.email')}/>
      <TextFieldComponent id={"phone_filter"} state={phoneFilter} pageChangerArr={[setPage]}
        stateChanger={setPhoneFilter} textLabel={t('page.fnb.orderUserExport.filter.phone')}/>
      <TextFieldComponent id={"name_filter"} state={userNameFilter} pageChangerArr={[setPage]}
        stateChanger={setUserNameFilter} textLabel={t('page.fnb.orderUserExport.filter.userName')}/>
      <MultiSelectComponent id={"store_code_filter"} state={storeCodeFilter} pageChangerArr={[setPage]}
        stateChanger={setStoreCodeFilter} textLabel={t('page.fnb.orderUserExport.filter.storeCode')} selectArray={storeCodeSelect}/>
    </Dashboard>,
    <Dashboard>
      <TableComponent item={item} result={result} rowsPerPage={rowsPerPage} 
        setRowsPerPage={setRowsPerPage} page={page} setPage={setPage}/>
    </Dashboard>
  ])
};

export default OrderUserExportPage;